import React, {FC, useState} from 'react'
import {Box, Divider, Drawer, Fab, Hidden, List, ListItem, SxProps, Theme} from '@mui/material'
import {ColorPanel} from './ColorPanel'
import {PalettePanel} from './PalettePanel'
import {ColorActions} from './ColorActions'
import {theme} from '../components/Theme'

const drawerWidth = 260

const classes: Record<string, SxProps<Theme>> = {
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    ['@media print']: {
      display: 'none',
    },
  },
}

type ToolbarProps = {
  window?: Window
  colors?: string[],
  setColors?: React.Dispatch<React.SetStateAction<string[]>>,
  paletteId?: string,
  setPaletteId?: React.Dispatch<React.SetStateAction<string>>
  paletteName?: string,
  setPaletteName?: React.Dispatch<React.SetStateAction<string>>,
  palettes?: Record<string, any>[],
  setPalettes?: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
}

export const Toolbar: FC<ToolbarProps> = (props) => {
  const {window} = props
  const [mobileOpen, setMobileOpen] = useState(false)

  const [colors, setColors] = (
    'colors' in props
    && 'setColors' in props
  )
    ? [props.colors, props.setColors]
    : useState([])

  const [paletteName, setPaletteName] = (
    'paletteName' in props
    && 'setPaletteName' in props
  )
    ? [props.paletteName, props.setPaletteName]
    : useState('')

  const [paletteId, setPaletteId] = (
    'paletteId' in props
    && 'setPaletteId' in props
  )
    ? [props.paletteId, props.setPaletteId]
    : useState(null)

  const [palettes, setPalettes] = (
    'palettes' in props
    && 'setPalettes' in props
  )
    ? [props.palettes, props.setPalettes]
    : useState([])

  const handleOnPaletteCreate = (createdPalette) => {
    const tmpPalettes = [...palettes]
    tmpPalettes.unshift(createdPalette)
    setPalettes(tmpPalettes)
  }
  const handleOnPaletteUpdate = (updatedPalette) => {
    const tmpPalettes = [...palettes]
    const foundIndex = tmpPalettes.findIndex(
      (palette) => palette['id'] === updatedPalette['id']
    )
    if (foundIndex > -1) {
      tmpPalettes[foundIndex] = updatedPalette
      setPalettes(tmpPalettes)
    }
  }
  const handleOnPaletteDelete = (deletedPalette) => {
    const tmpPalettes = [...palettes]
    const foundIndex = tmpPalettes.findIndex(
      (palette) => palette['id'] === deletedPalette['id']
    )
    if (foundIndex > -1) {
      tmpPalettes.splice(foundIndex, 1)
      setPalettes(tmpPalettes)
    }
  }

  const handleDrawerToggle = (event) => {
    setMobileOpen(!mobileOpen)
  }

  const container = window !== undefined
    ? () => window.document.body
    : undefined

  const drawer = (
    <List>
      <ListItem>
        <ColorActions
          colors={colors}
          setColors={setColors}
          paletteName={paletteName}
          setPaletteName={setPaletteName}
          paletteId={paletteId}
          setPaletteId={setPaletteId}
          onPaletteCreate={handleOnPaletteCreate}
          onPaletteUpdate={handleOnPaletteUpdate}
          onPaletteDelete={handleOnPaletteDelete}
        />
      </ListItem>
      <ListItem>
        <ColorPanel
          colors={colors}
          setColors={setColors}
        />
      </ListItem>
      <Divider/>
      <ListItem>
        <PalettePanel
          setColors={setColors}
          setPaletteName={setPaletteName}
          setPaletteId={setPaletteId}
          palettes={palettes}
          setPalettes={setPalettes}
        />
      </ListItem>
    </List>
  )

  return (
    <Box component={'nav'} sx={classes.drawer}>
      <Hidden smUp implementation="css">
        <Fab
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          }}
          onClick={handleDrawerToggle}
        >
          <span className="material-icons">palette</span>
        </Fab>
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              paddingBottom: 8,
              width: drawerWidth
            }
          }}
          ModalProps={{keepMounted: true}}
          // Below navigation/NavBar Fab component
          sx={{zIndex: theme.zIndex.fab - 1}}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="right"
          variant="permanent"
          open
          PaperProps={{sx: {width: drawerWidth}}}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Box>
  )
}