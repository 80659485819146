import React, {FC, useEffect, useState} from 'react'
import {PaletteSwatches} from './PaletteSwatches'
import {usePrivateAPIClient} from '../components/APIClient'

type PalettePanelProps = {
  palettes?: Record<string, any>[],
  setPalettes?: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
  setColors?: (data: string[]) => void,
  setPaletteName?: (data: string) => void,
  setPaletteId?: (data: string) => void,
}

export const PalettePanel: FC<PalettePanelProps> = (props: PalettePanelProps) => {
  const [palettes, setPalettes] = (
    'palettes' in props
    && 'setPalettes' in props
  )
    ? [props.palettes, props.setPalettes]
    : useState([])

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const apiClient = usePrivateAPIClient()
  const fetchData = async () => {
    setError(null)
    setIsLoading(true)
    try {
      const url = `/palettes/?page=${page}&page_size=${pageSize}`
      const result = await apiClient.get(url)
      setPalettes(result.data.results)
      if (result.data.count > 0) {
        setPageSize(result.data.pagination.size)
      }
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const handleSwatchClick = (palette) => {
    if (palette != null) {
      const colors = ('colors' in palette) ? palette['colors'] : []
      if (
        'setColors' in props
        && (typeof props.setColors) == 'function'
      ) {
        props.setColors(colors)
      }
      const name = ('name' in palette) ? palette['name'] : ''
      if (
        'setPaletteName' in props
        && (typeof props.setPaletteName) == 'function'
      ) {
        props.setPaletteName(name)
      }
      const id = ('id' in palette) ? palette['id'] : ''
      if (
        'setPaletteId' in props
        && (typeof props.setPaletteId) == 'function'
      ) {
        props.setPaletteId(id)
      }
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (isLoading) {
    return <div>Loading...</div>
  } else if (palettes.length === 0) {
    return <div>No result.</div>
  } else {
    return (
      <div>
        {palettes.map((palette, index) =>
          <PaletteSwatches
            id={palette['id']}
            name={palette['name']}
            colors={palette['colors']}
            onClick={handleSwatchClick}
            key={index}
          />
        )}
      </div>
    )
  }
}