import React, {FC, useEffect, useRef, useState} from 'react'
import {ColorSwatch} from './ColorSwatch'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {ChromePicker} from 'react-color'

// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

type ColorPanelProps = {
  colors?: string[],
  setColors?: React.Dispatch<React.SetStateAction<string[]>>
}

export const ColorPanel: FC<ColorPanelProps> = (props) => {
  const [colors, setColors] = ('colors' in props && 'setColors' in props)
    ? [props.colors, props.setColors]
    : useState([])

  const prevColors: string[] = usePrevious(colors)

  const [pickerActive, setPickerActive] = useState<boolean>(false)
  const [activeColor, setActiveColor] = useState<string>(null)
  const [activeIndex, setActiveIndex] = useState<number>(null)

  const handleColorClick = (color, index) => {
    setActiveColor(color)
    setActiveIndex(index)
    setPickerActive(true)
  }

  const handleDeleteClick = (color, index) => {
    if (index === activeIndex) {
      setActiveColor(null)
      setActiveIndex(null)
      setPickerActive(false)
    }
    const tmpColors = [...colors]
    tmpColors.splice(index, 1)
    setColors(tmpColors)
  }

  const handlePickerChange = (pickedColor, index) => {
    const tmpColors = [...colors]
    tmpColors[activeIndex] = pickedColor.hex
    setColors(tmpColors)
    setActiveColor(pickedColor.hex)
  }
  const handlePickerChangeComplete = (pickedColor) => {
    console.log(pickedColor)
  }

  const onDragEnd = (result) => {
    if (!result.destination
      || result.destination.index === result.source.index) {
      return
    }
    setColors(reorder(
      colors,
      result.source.index,
      result.destination.index,
    ))
    setActiveIndex(result.destination.index)
  }

  useEffect(() => {
    if (pickerActive) {
      if (colors.length === 0) {
        setActiveColor(null)
        setActiveIndex(null)
        setPickerActive(false)
      } else if (prevColors && colors.length !== prevColors.length) {
        const index = colors.length - 1
        const color = colors[index]
        setActiveColor(color)
        setActiveIndex(index)
      }
    }
  }, [colors])

  return (
    <div>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="drop">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {colors.map((color, index) =>
                  <Draggable
                    draggableId={`drag-${index}`}
                    index={index}
                    key={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <ColorSwatch
                          color={color}
                          key={index}
                          onColorClick={() => handleColorClick(color, index)}
                          onDeleteClick={() => handleDeleteClick(color, index)}
                        />
                      </div>
                    )}
                  </Draggable>,
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {pickerActive &&
      <ChromePicker
        color={activeColor}
        disableAlpha={true}
        onChange={handlePickerChange}
        onChangeComplete={handlePickerChangeComplete}
      />
      }
    </div>
  )
}