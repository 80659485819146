import React, {FC, useState} from 'react'
import {DataGrid, GridColDef, GridRowModel, GridSelectionModel, GridSortModel} from '@mui/x-data-grid'
import {Box} from '@mui/material'
import {ScoresGrid} from './ScoresGrid'
import {SourcesGrid} from './SourcesGrid'
import {dataGridDefaultProps, getProjectCountFromSources, GridCellExpand,} from './utils'
import {JsonData} from './JsonData'

type ModuleGridSingleProps = {
  data?: Record<string, any>[]
}

export const ModuleGridSingle: FC<ModuleGridSingleProps> = ({data}) => {
  if (data == null) return <></>

  const [scoresData, setScoresData] = useState()
  const [sourcesData, setSourcesData] = useState()
  const [jsonData, setJsonData] = useState<string>()

  const handleOnRowSelected = (row) => {
    setScoresData(row['scores'])
    setSourcesData(row['sources'])
    setJsonData(formatJsonDataFromFields(row['fields']))
  }
  const handleOnSelectionModelChange = (gridSelectionModel: GridSelectionModel) => {
    const index = gridSelectionModel[0]
    const row = rows[index]
    if (row) {
      handleOnRowSelected(row)
    }
  }

  const formatJsonDataFromFields = (fields) => {
    const dict = {}
    fields.reduce(
      (acc, field) => {
        acc[field['name']] = field['value']
        return acc
      },
      dict
    )
    return JSON.stringify(dict, null, 2)
  }

  const renderCellExpand = (gridCellParams) => {
    const fields = gridCellParams.value
    const value = fields.map(
      (field) => `${field['name']}:${field['value']}`
    ).join(', ')
    const valueExtended = fields.map(
      (field) => `${field['name']}: ${field['value']}`
    ).join('\n')
    return <GridCellExpand value={value} valueExtended={valueExtended}/>
  }


  const name = data[0]['data']['name']
  const rows: GridRowModel[] = data.map((token, index) => {
    return {
      id: index,
      fields: token['data']['fields'],
      power: token['power'],
      weight: token['weight'],
      scores: token['scores'],
      sources: token['sources'],
      projectCount: getProjectCountFromSources(token['sources']),
    }
  })

  const columns: GridColDef[] = [
    {
      field: 'fields',
      headerName: 'Expand',
      flex: 2,
      renderCell: renderCellExpand,
    },
    {field: 'power', headerName: 'Power', flex: 1},
    {field: 'weight', headerName: 'Weight', flex: 1},
    {field: 'projectCount', headerName: 'Projects', flex: 1},
  ]

  const sortModel: GridSortModel = [{field: 'power', sort: 'desc'}]

  return (
    <Box>
      <h2>Variants: {name}</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{sorting: {sortModel}}}
        onSelectionModelChange={(e) => {
          handleOnSelectionModelChange(e)
        }}
        {...dataGridDefaultProps} />
      <JsonData data={jsonData}/>
      <SourcesGrid data={sourcesData}/>
      <ScoresGrid data={scoresData}/>
    </Box>
  )
}