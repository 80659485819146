import React, {FC} from 'react'
import {DataGrid, GridColDef, GridRowModel, GridSortModel} from '@mui/x-data-grid'
import {Box, Button} from '@mui/material'
import {groupBy} from 'lodash'
import {dataGridDefaultProps} from './utils'

type SourcesGridProps = {
  data?: Record<string, any>
}

export const SourcesGrid: FC<SourcesGridProps> = ({data}) => {
  if (data == null) return <></>

  const handleViewArtwork = (row) => {
    // ex: [ [project, [batch_id, ...]], ... ]
    // See server/api/views/artwork_view.py
    const re = /(.+)-(\d+)-(\d+)\.json$/
    const queryData = [
      [
        row['name'],
        row['sources'].map((source) => {
          const match = source.match(re)
          return parseInt(match[2], 10)
        })
      ]
    ]
    const queryString = JSON.stringify(queryData)
    const url = `${window.location.origin}/grid/1/?insight_sources=${queryString}`
    window.open(url)
  }


  const grouped = groupBy(data, (source) => {
    return source.split('-')[0]
  })

  const rows: GridRowModel[] = []
  let index = 0
  for (const key in grouped) {
    rows.push({
      id: index,
      name: key,
      count: grouped[key].length,
      sources: grouped[key],
    })
    index++
  }

  const columns: GridColDef[] = [
    {field: 'name', headerName: 'Name', flex: 1},
    {field: 'count', headerName: 'Count', flex: 1},
    {
      field: '',
      headerName: 'Action',
      sortable: false,
      // disableClickEventBubbling: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => handleViewArtwork(params['row'])}
            >
              Artwork
            </Button>
          </>
        )
      }
    }
  ]

  const sortModel: GridSortModel = [{field: 'name', sort: 'asc'}]

  return (
    <Box>
      <h2>Sources</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{sorting: {sortModel}}}
        {...dataGridDefaultProps} />
    </Box>
  )
}