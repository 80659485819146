import React from 'react'
import {Box, Popover} from '@mui/material'
import {groupBy} from 'lodash'
import {DataGridProps} from '@mui/x-data-grid'

export const dataGridDefaultProps: Partial<DataGridProps> = {
  density: 'compact',
  autoHeight: true,
  pageSize: 30,
  sortingOrder: ['desc', 'asc', null],
}

type GridCellExpandProps = {
  value: any,
  valueExtended: any
}

export const GridCellExpand = React.memo(function GridCellExpand({
  value,
  valueExtended
}: GridCellExpandProps) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  return (
    <Box
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {value}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{pointerEvents: 'none'}}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        disableScrollLock
      >
        <Box sx={{p: 2}}>
          <pre style={{margin: 0}}>{valueExtended}</pre>
        </Box>
      </Popover>
    </Box>
  )
})

export const getProjectCountFromSources = (sources) => {
  const grouped = groupBy(sources, (source) => {
    return source.split('-')[0]
  })
  return Object.keys(grouped).length
}