import axios from 'axios'

// Private
const _getStoredAuthorizationToken = () => {
  return localStorage.getItem('api-token')
}
const _setStoredAuthorizationToken = (token) => {
  localStorage.setItem('api-token', token)
}

const apiConfig = {
  baseURL: '/api/',
}

const publicAPIClient = axios.create({
  baseURL: apiConfig.baseURL,
})

const privateAPIClient = axios.create({
  baseURL: apiConfig.baseURL,
  headers: {
    common: {
      'Authorization': `Token ${_getStoredAuthorizationToken()}`,
    },
  },
})

privateAPIClient.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  switch (error.response.status) {
    case 401: {
      const url = new URL(error.request.responseURL)
      if (url.pathname !== apiConfig.baseURL + 'me/') {
        window.location.href = '/login'
      }
    }
      break
    case 403:
      console.log('TRY TO REFRESH THE TOKEN')
      break
  }
  Promise.reject(error).then()
})


// Export Public
export const usePublicAPIClient = () => {
  return publicAPIClient
}

export const usePrivateAPIClient = () => {
  return privateAPIClient
}

export const isAuthorizationTokenSet = () => {
  return _getStoredAuthorizationToken()
}

export const setAuthorizationToken = (token) => {
  privateAPIClient.defaults.headers.common['Authorization'] = `Token ${token}`
  _setStoredAuthorizationToken(token)
}

export const clearAuthorizationToken = () => {
  setAuthorizationToken('')
}