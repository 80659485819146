import React, {FC,} from 'react'
import {Box, Card, CardActionArea, CardContent, CardMedia, Skeleton, SxProps, Theme, Typography,} from '@mui/material'


const classes: Record<string, SxProps<Theme>> = {
  artwork: {
    paddingLeft: 0.5,
    paddingRight: 0.5,
    paddingBottom: 0.5,
    paddingTop: 0.5,
    width: '100%',
  },
  card: {
    margin: 'auto',
    maxWidth: '550px',
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '100%', // 1:1
  },
}

type ArtworkVotingProps = {
  artwork: Record<string, any>,
  onClick?: (artwork: Record<string, any>) => void
}

export const ArtworkVoting: FC<ArtworkVotingProps> = (props) => {
  const score = props.artwork.feedbacks[0].score || 0
  let scoreIcon = ''
  switch (score) {
    case -2:
      scoreIcon = 'not_interested'
      break
    case -1:
      scoreIcon = 'thumb_down_alt'
      break
    case 1:
      scoreIcon = 'thumb_up_alt'
      break
    case 2:
      scoreIcon = 'star'
      break
  }

  const handleClick = () => {
    if (props.onClick != null && (typeof props.onClick) == 'function') {
      props.onClick(props.artwork)
    }
  }
  return (
    <Box sx={classes.artwork}>
      <Card raised sx={classes.card}>
        <CardActionArea onClick={handleClick}>
          <CardContent>
            <Typography variant="body2">
              {props.artwork.name}
              <span
                className="material-icons"
                style={{
                  fontSize: '14px',
                  marginLeft: '6px',
                  verticalAlign: 'text-top'
                }}
              >
                {scoreIcon}
              </span>
            </Typography>
            <Typography variant="body2">

            </Typography>
          </CardContent>
          <CardMedia
            sx={classes.media}
            image={props.artwork.preview_file}
            title={props.artwork.name}
          />
        </CardActionArea>
      </Card>
    </Box>
  )
}

export const ArtworkVotingSkeleton = function () {
  return (
    <Box sx={classes.artwork}>
      <Card raised sx={classes.card}>
        <CardContent>
          <Typography variant="body2">
            <Skeleton animation="wave" width="60%"/>
          </Typography>
        </CardContent>
        <Skeleton
          sx={classes.media}
          variant="rectangular"
          animation="wave"
        />
      </Card>
    </Box>
  )
}