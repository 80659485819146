import React, {FC, useEffect, useState} from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material'
import axios from 'axios'

const classes: Record<string, SxProps<Theme>> = {
  card: {
    maxWidth: 530,
    margin: 'auto',
    ['@media print']: {
      boxShadow: 'none',
    },
  },
  cardContent: {
    padding: '16px !important',
  },
  svg: {
    '& svg': {
      height: '100%',
      width: '100%',
    },
  }
}


// https://stackoverflow.com/a/1740716
const rgb2hex = (orig) => {
  const rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+)/i)
  return (rgb && rgb.length === 4) ? '#' +
    ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
    ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
    ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2) : orig
}

// https://stackoverflow.com/a/46403589
const saveSvg = (svgEl, name) => {
  svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
  const svgData = svgEl.outerHTML
  const preface = '<?xml version="1.0" standalone="no"?>\r\n'
  const svgBlob = new Blob([preface, svgData],
    {type: 'image/svg+xml;charset=utf-8'})
  const svgUrl = URL.createObjectURL(svgBlob)
  const downloadLink = document.createElement('a')
  downloadLink.href = svgUrl
  downloadLink.download = name
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

type SvgCardProps = {
  artwork?: string,
  colors?: string[]
}

export const SvgCard: FC<SvgCardProps> = (props: SvgCardProps) => {
  const [artwork] = ('artwork' in props)
    ? [props.artwork, null]
    : useState('basics')

  const [colors] = ('colors' in props)
    ? [props.colors, null]
    : useState([])

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [svgData, setSvgData] = useState(null)
  const [cssRules, setCssRules] = useState([])
  const [orgClassFillList, setOrgClassFillList] = useState([])
  const [activeCssRules, setActiveCssRules] = useState<boolean[]>([])

  const fetchSVG = async (artwork) => {
    setError(null)
    setIsLoading(true)
    try {
      const url = `/static/svg/${artwork}.svg`
      const result = await axios.get(url)
      setSvgData(result.data)
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  const handleFileInput = (file) => {
    setError(null)
    setIsLoading(true)
    const reader = new FileReader()
    reader.onload = (e) => {
      setIsLoading(false)
      try {
        const result = e.target.result
        setSvgData(result)
      } catch (error) {
        setError(error)
      }
    }
    reader.readAsText(file)
  }

  const handleDownloadClick = () => {
    const svgEl = document.getElementById('svg').getElementsByTagName('svg')[0].cloneNode(true) as SVGSVGElement
    svgEl.getElementsByTagName('style')[0].innerHTML = orgClassFillList.map(
      (orgClassFill, index) => {
        const color = activeCssRules[index]
          ? rgb2hex(cssRules[index].style['fill'])
          : orgClassFillList[index].fill
        return `${orgClassFill.selectorText}{fill:${color}}`
      }).join('')
    saveSvg(svgEl, 'recolor.svg')
  }

  const applyColorsToCssRules = (colors) => {
    if (colors.length === 0 || cssRules.length === 0) {
      return
    }
    const colors_lg = colors.length
    let loop = 0
    cssRules.forEach((cssRule, index) => {
      if (!activeCssRules[index]) {
        cssRule.style['fill'] = orgClassFillList[index].fill
        return
      }
      let color_index = index - (colors_lg * loop)
      if (color_index >= colors_lg) {
        loop++
        color_index = index - (colors_lg * loop)
      }
      cssRule.style['fill'] = colors[color_index]
    })
  }

  const extractSvgCssRules = () => {
    const svgStyleSheet = Object.values(document.styleSheets).find(
      cssStyleSheet => cssStyleSheet['title'] === 'svg',
    )
    if (svgStyleSheet != null) {
      let tmpCssRules = Object.values(svgStyleSheet.rules)
      tmpCssRules = tmpCssRules.filter((cssRule: CSSStyleRule) => cssRule.style['fill'] !== '')
      return tmpCssRules
    }
    return []
  }

  useEffect(() => {
    fetchSVG(artwork)
  }, [artwork])

  useEffect(() => {
    setOrgClassFillList([])
    setCssRules([])
    setActiveCssRules([])
    // Sadly, we need a setTimeout to let the dom catch the css before applying.
    const handler = setTimeout(() => {
      const cssRules = extractSvgCssRules()
      const activeCssRules = cssRules.map((cssRule: CSSStyleRule) => {
        const hex = rgb2hex(cssRule.style.fill)
        // only activate rules that aren't in the list
        return (['#000000', '#ffffff'].indexOf(hex) === -1)
      })
      const orgClassFillList = cssRules.map((cssRule: CSSStyleRule) => {
        return {
          'selectorText': cssRule.selectorText,
          'fill': rgb2hex(cssRule.style.fill),
        }
      })
      setOrgClassFillList(orgClassFillList)
      setCssRules(cssRules)
      setActiveCssRules(activeCssRules)
    }, 0)
    return () => {
      clearTimeout(handler)
    }
  }, [svgData])

  useEffect(() => {
    applyColorsToCssRules(colors)
  }, [colors, activeCssRules])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (isLoading) {
    return <div>Loading...</div>
  } else if (svgData == null) {
    return <div>No svg loaded.</div>
  } else {
    return (
      <div>
        <Card raised sx={classes.card}>
          <CardContent sx={classes.cardContent}>
            <Box sx={classes.svg} id="svg" dangerouslySetInnerHTML={{__html: svgData}}/>
            {orgClassFillList.length && activeCssRules.length &&
            <SvgCssRules
              orgClassFillList={orgClassFillList}
              activeCssRules={activeCssRules}
              setActiveCssRules={
                (_activeCssRules) => setActiveCssRules(_activeCssRules)
              }/>
            }
          </CardContent>
          <CardActions>
            <IconButton
              size="small"
              component="label"
            >
              <span className="material-icons">file_upload</span>
              <input type="file"
                hidden
                onChange={(e) => handleFileInput(e.target.files[0])}/>
            </IconButton>
            <IconButton
              size="small"
              sx={{marginLeft: 'auto'}}
              onClick={() => {
                handleDownloadClick()
              }}>
              <span className="material-icons">download</span>
            </IconButton>
          </CardActions>
        </Card>
      </div>
    )
  }
}

type SvgCssRulesProps = {
  orgClassFillList: Record<string, any>[],
  activeCssRules: boolean[],
  setActiveCssRules: React.Dispatch<React.SetStateAction<boolean[]>>
}

export const SvgCssRules: FC<SvgCssRulesProps> = ({
  orgClassFillList,
  activeCssRules,
  setActiveCssRules,
}: SvgCssRulesProps) => {
  const setSingleActiveCssRule = (index, isActive) => {
    const tmpActiveCssRules = [...activeCssRules]
    tmpActiveCssRules[index] = isActive
    setActiveCssRules(tmpActiveCssRules)
  }

  return (
    <div>
      {orgClassFillList.map((orgClassFill, index) =>
        <CssRuleToggle
          key={index}
          orgClassFill={orgClassFill}
          isActive={activeCssRules[index]}
          setIsActive={(isActive) => {
            setSingleActiveCssRule(index, isActive)
          }}
        />,
      )}
    </div>
  )
}

type CssRuleToggleProps = {
  orgClassFill: Record<string, any>,
  isActive: boolean,
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const CssRuleToggle: FC<CssRuleToggleProps> = ({
  orgClassFill,
  isActive,
  setIsActive
}: CssRuleToggleProps) => {
  const color = orgClassFill.fill
  const label = '#' + orgClassFill.selectorText.substring(
    orgClassFill.selectorText.indexOf('-') + 1,
  ).toLowerCase()

  const handleChange = (event) => {
    setIsActive(!isActive)
  }

  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={isActive}
          sx={{
            color: color,
            '&.MuiChecked': {
              color: color,
            },
          }}
          onChange={handleChange}
        />
      }
    />
  )
}