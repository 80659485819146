import React, {FC, forwardRef, Ref, useEffect, useImperativeHandle, useState,} from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Skeleton,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

import {ExtraScores} from './ExtraScores'
import {ScoreToggle} from './ScoreToggle'
import {CommentForm} from './CommentForm'
import {usePrivateAPIClient} from '../components/APIClient'
import {theme} from '../components/Theme'


const classes: Record<string, SxProps<Theme>> = {
  artwork: {
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 2,
    paddingTop: 1,
    width: '100%',
  },
  card: {
    margin: 'auto',
    maxWidth: '550px',
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '100%', // 1:1
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: 1,
    },
    '&:first-child': {
      borderRadius: 1,
    },
  },
}

type ArtworkProps = {
  artwork: Record<string, any>
  slideIndex: number,
  ref?: Ref<unknown>
}

export const Artwork: FC<ArtworkProps> = forwardRef((props: ArtworkProps, ref) => {
  const [expanded, setExpanded] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleDownloadMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDownloadMenuClose = () => {
    setAnchorEl(null)
  }

  let feedback = props.artwork['feedbacks'][0] || {}
  const [score, setScore] = useState(feedback['score'] || 0)
  const [comment, setComment] = useState(feedback['comment'] || '')
  const [extraScores, setExtraScores] = useState({
    'color': feedback['color_score'] || 0,
    'count': feedback['count_score'] || 0,
    'location': feedback['location_score'] || 0,
    'rotation': feedback['rotation_score'] || 0,
    'scale': feedback['scale_score'] || 0,
    'shadow': feedback['shadow_score'] || 0,
    'shape': feedback['shape_score'] || 0,
  })

  const [updateCount, setUpdateCount] = useState(-1)
  useEffect(() => {
    setUpdateCount(updateCount + 1)
  }, [score, comment, extraScores])

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const apiClient = usePrivateAPIClient()
  const postData = async () => {
    setError(null)
    setIsLoading(true)
    const body = {
      'score': score,
      'comment': comment,
      'color_score': extraScores['color'],
      'count_score': extraScores['count'],
      'location_score': extraScores['location'],
      'rotation_score': extraScores['rotation'],
      'scale_score': extraScores['scale'],
      'shadow_score': extraScores['shadow'],
      'shape_score': extraScores['shape'],
    }
    // API will automatically update or create based on artwork id and logged user.
    try {
      body['artwork'] = props.artwork['id']
      const result = await apiClient.post(
        '/feedbacks/',
        body,
      )
      feedback = result.data
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      if (updateCount > 0) {
        postData()
      }
    }, 1500)
    return () => {
      clearTimeout(handler)
    }
  }, [updateCount])

  useImperativeHandle(ref, () => ({
    onSlideIndexChange: (index, indexLatest) => {
      // Save data on slide out of view.
      if (indexLatest === props.slideIndex && index !== props.slideIndex) {
        postData()
      }
    },
  }))

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Box sx={classes.artwork}>
      <Card raised sx={classes.card}>
        <CardHeader
          title={props.artwork.name}
          subheader={props.artwork.created_at}
          action={
            <div>
              <IconButton
                size="small"
                aria-controls="download-menu"
                aria-haspopup="true"
                onClick={handleDownloadMenuOpen}
              >
                <span className="material-icons">download</span>
              </IconButton>
              <Menu
                id="download-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleDownloadMenuClose}
              >
                <MenuItem onClick={handleDownloadMenuClose}>
                  <Link
                    color="inherit"
                    display="block"
                    href={props.artwork.preview_file}
                    download
                  >
                    PNG
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleDownloadMenuClose}>
                  <Link
                    color="inherit"
                    href={props.artwork.artwork_file}
                    download
                  >
                    PDF
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleDownloadMenuClose}>
                  <Link
                    color="inherit"
                    href={props.artwork.config_file}
                    download
                  >
                    JSON
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleDownloadMenuClose}>
                  <Link
                    color="inherit"
                    href={props.artwork.render_sheet_file}
                    download
                  >
                    CSV
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          }
        />
        <CardMedia
          sx={classes.media}
          image={props.artwork.preview_file}
          title={props.artwork.name}
        />
        <CardActions disableSpacing>
          <ScoreToggle
            score={score}
            setScore={setScore}
          />
          <IconButton
            sx={!expanded ? classes.expand : classes.expandOpen}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            size="large">
            <span className="material-icons">expand_more</span>
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto">
          <CardContent>
            <ExtraScores
              extraScores={extraScores}
              setExtraScores={setExtraScores}
            />
            <CommentForm
              comment={comment}
              setComment={setComment}
            />
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  )
})
Artwork.displayName = 'Artwork'

export const ArtworkSkeleton = function () {
  return (
    <Box sx={classes.artwork}>
      <Card raised sx={classes.card}>
        <CardHeader
          title={<Skeleton animation="wave" width="80%"/>}
          subheader={<Skeleton animation="wave" width="60%"/>}
        />
        <Skeleton
          sx={classes.media}
          variant="rectangular"
          animation="wave"
        />
        <CardActions disableSpacing>
          <ToggleButtonGroup
            exclusive
            sx={{
              '& .MuiToggleButtonGroup-grouped': classes.grouped
            } as SxProps}
          >
            <ToggleButton value={-2}>
              <Skeleton variant="rectangular" width={24} height={24} animation="wave"/>
            </ToggleButton>
            <ToggleButton value={-1}>
              <Skeleton variant="rectangular" width={24} height={24} animation="wave"/>
            </ToggleButton>
            <ToggleButton value={1}>
              <Skeleton variant="rectangular" width={24} height={24} animation="wave"/>
            </ToggleButton>
            <ToggleButton value={2}>
              <Skeleton variant="rectangular" width={24} height={24} animation="wave"/>
            </ToggleButton>
            <ToggleButton value={0}>
              <Skeleton variant="rectangular" width={24} height={24} animation="wave"/>
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton sx={classes.expand} size="large">
            <Skeleton variant="rectangular" width={24} height={24} animation="wave"/>
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  )
}