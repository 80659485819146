import React, {FC, useState} from 'react'
import {List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader,} from '@mui/material'

import {ExtraScoreToggle} from './ExtraScoreToggle'

type ExtraScoresProps = {
  extraScores?: Record<string, any>
  setExtraScores?: React.Dispatch<React.SetStateAction<Record<string, any>>>
}

export const ExtraScores: FC<ExtraScoresProps> = (props) => {
  const [extraScores, setExtraScores] = ('extraScores' in props &&
    'setExtraScores' in props) ?
    [props.extraScores, props.setExtraScores] :
    useState({
      'color': 0,
      'count': 0,
      'location': 0,
      'rotation': 0,
      'scale': 0,
      'shadow': 0,
      'shape': 0,
    })

  const setExtraScoreByName = (newScore, name) => {
    // shallow copy is sufficient since the object doesn't contain references.
    const newExtraScores = {...extraScores}
    newExtraScores[name] = newScore;
    ('setExtraScores' in props) ?
      props.setExtraScores(newExtraScores) :
      setExtraScores(newExtraScores)
  }

  const setColorScore = (newScore) => {
    setExtraScoreByName(newScore, 'color')
  }
  const setCountScore = (newScore) => {
    setExtraScoreByName(newScore, 'count')
  }
  const setLocationScore = (newScore) => {
    setExtraScoreByName(newScore, 'location')
  }
  const setRotationScore = (newScore) => {
    setExtraScoreByName(newScore, 'rotation')
  }
  const setScaleScore = (newScore) => {
    setExtraScoreByName(newScore, 'scale')
  }
  const setShadowScore = (newScore) => {
    setExtraScoreByName(newScore, 'shadow')
  }
  const setShapeScore = (newScore) => {
    setExtraScoreByName(newScore, 'shape')
  }

  return (
    <List subheader={
      <ListSubheader color={'inherit'}>Extra feedback</ListSubheader>
    }>
      <ListItem>
        <ListItemText primary={'Colors'}/>
        <ListItemSecondaryAction>
          <ExtraScoreToggle
            score={extraScores.color}
            setScore={setColorScore}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary={'Density'}/>
        <ListItemSecondaryAction>
          <ExtraScoreToggle
            score={extraScores.count}
            setScore={setCountScore}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary={'Orientation'}/>
        <ListItemSecondaryAction>
          <ExtraScoreToggle
            score={extraScores.rotation}
            setScore={setRotationScore}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary={'Positioning'}/>
        <ListItemSecondaryAction>
          <ExtraScoreToggle
            score={extraScores.location}
            setScore={setLocationScore}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary={'Scaling'}/>
        <ListItemSecondaryAction>
          <ExtraScoreToggle
            score={extraScores.scale}
            setScore={setScaleScore}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary={'Shadows'}/>
        <ListItemSecondaryAction>
          <ExtraScoreToggle
            score={extraScores.shadow}
            setScore={setShadowScore}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary={'Shapes'}/>
        <ListItemSecondaryAction>
          <ExtraScoreToggle
            score={extraScores.shape}
            setScore={setShapeScore}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}