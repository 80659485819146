import React, {FC} from 'react'
import {DataGrid, GridColDef, GridRowModel, GridSortModel} from '@mui/x-data-grid'
import {Box, Button} from '@mui/material'
import {dataGridDefaultProps} from './utils'

type ScoresGridProps = {
  data?: Record<string, any>
}

export const ScoresGrid: FC<ScoresGridProps> = ({data}) => {
  if (data == null) return <></>

  const handleViewArtwork = (row) => {
    const configFile = row['configFile']
    if (configFile && configFile !== '') {
      const re = /(.+)-(\d+)-(\d+)\.json$/
      const match = configFile.match(re)
      const queryString = `${match[1]}-${match[2]}`
      const url = `${window.location.origin}/grid/1/?search=${queryString}`
      window.open(url)
    }
  }

  const count = data['score'].length

  const rows: GridRowModel[] = []
  for (let i = 0; i < count; i++) {
    rows.push({
      id: i,
      score: data['score'][i],
      color: data['color_score'][i],
      count: data['count_score'][i],
      location: data['location_score'][i],
      rotation: data['rotation_score'][i],
      scale: data['scale_score'][i],
      shadow: data['shadow_score'][i],
      shape: data['shape_score'][i],
      configFile: ('config_file' in data) ? data['config_file'][i] : null,
    })
  }

  const columns: GridColDef[] = [
    {field: 'configFile', headerName: 'Config file', flex: 2},
    {field: 'score', headerName: 'Score', flex: 1},
    {field: 'color', headerName: 'Color', flex: 1},
    {field: 'count', headerName: 'Count', flex: 1},
    {field: 'location', headerName: 'Location', flex: 1},
    {field: 'rotation', headerName: 'Rotation', flex: 1},
    {field: 'scale', headerName: 'Scale', flex: 1},
    {field: 'shadow', headerName: 'Shadow', flex: 1},
    {field: 'shape', headerName: 'Shape', flex: 1},
    {
      field: '',
      headerName: 'Action',
      sortable: false,
      // disableClickEventBubbling: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => handleViewArtwork(params['row'])}
            >
              Artwork
            </Button>
          </>
        )
      }
    }
  ]

  const sortModel: GridSortModel = [{field: 'score', sort: 'desc'}]

  return (
    <Box>
      <h2>Scores</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{sorting: {sortModel}}}
        {...dataGridDefaultProps} />
    </Box>
  )
}