import React, {FC, useEffect, useState} from 'react'
import {Box, Grid, Pagination, SxProps, Theme} from '@mui/material'
import {useHistory} from 'react-router-dom'

import queryString, {ParsedQuery} from 'query-string'

import {Artwork, ArtworkSkeleton} from '../artwork/Artwork'
import {usePrivateAPIClient} from '../components/APIClient'
import {theme} from '../components/Theme'

const classes: Record<string, SxProps<Theme>> = {
  pagination: {
    margin: `${theme.spacing(1)} auto ${theme.spacing(2)}`,
  }
}

type ArtworkGridPageProps = {
  page: string
  path: string
  searchParams: ParsedQuery
}

export const ArtworkGridPage: FC<ArtworkGridPageProps> = (props) => {
  const history = useHistory()

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<Record<string, any>>({})

  const [page, setPage] = useState(parseInt(props.page) || 1)
  const [pageSize, setPageSize] = useState(21)
  const [pageCount, setPageCount] = useState(1)

  const [searchParams] = useState(props.searchParams || {})

  const apiClient = usePrivateAPIClient()
  const fetchData = async () => {
    setError(null)
    setIsLoading(true)
    try {
      let url = `/artworks/?page=${page}&page_size=${pageSize}`
      for (const key in searchParams) {
        switch (key) {
          case 'score':
            url += `&score=${searchParams[key]}`
            break
          case 'is_machine':
            url += `&is_machine=${searchParams[key]}`
            break
          case 'artist':
            url += `&artist_name=${searchParams[key]}`
            break
          case 'project':
            url += `&project_name=${searchParams[key]}`
            break
          case 'insight_sources':
            url += `&insight_sources=${searchParams[key]}`
            break
          case 'search':
            url += `&search=${searchParams[key]}`
        }
      }

      const result = await apiClient.get(url)
      setData(result.data)

      if (result.data.count > 0) {
        const tmpPageSize = result.data.pagination.size
        setPageSize(tmpPageSize)
        const tmpPageCount = Math.ceil(
          result.data.count / result.data.pagination.size
        )
        setPageCount(tmpPageCount)
      }
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page]) // https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect

  const handlePaginationChange = (event, newPage) => {
    if ('path' in props) {
      const historyParams = {
        'pathname': props.path.replace(':page', newPage)
      }
      if ('searchParams' in props && props.searchParams !== {}) {
        historyParams['search'] = queryString.stringify(props.searchParams)
      }
      history.replace(historyParams)
    } else {
      setPage(newPage)
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (isLoading) {
    return (
      <Box>
        <Grid container spacing={0}>
          {[...Array(6)].map((x, index) =>
            <Grid container item xs={12} sm={6} md={4} key={index}>
              <ArtworkSkeleton key={index}/>
            </Grid>
          )}
        </Grid>
      </Box>
    )
  } else if (data.count === 0) {
    return <div>No result.</div>
  } else {
    return (
      <Box>
        <Box alignItems="center" display="flex">
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePaginationChange}
            sx={classes.pagination}
          />
        </Box>
        <Grid container spacing={0}>
          {data.results.map((artwork, index) =>
            <Grid container item xs={12} sm={6} md={4} key={artwork.id}>
              <Artwork
                key={artwork.id}
                artwork={artwork}
                slideIndex={index}
              />
            </Grid>
          )}
        </Grid>
        <Box alignItems="center" display="flex">
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePaginationChange}
            sx={classes.pagination}
          />
        </Box>
      </Box>
    )
  }
}