import {createTheme, ThemeOptions} from '@mui/material/styles'

const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: '#424242',
    },
  }
}
export const theme = createTheme(darkTheme)