import React, {FC, useState} from 'react'
import {DataGrid, GridColDef, GridRowModel, GridSelectionModel, GridSortModel} from '@mui/x-data-grid'
import {Box} from '@mui/material'
import {ScoresGrid} from './ScoresGrid'
import {SourcesGrid} from './SourcesGrid'
import {dataGridDefaultProps, getProjectCountFromSources} from './utils'

type FieldGridSingleProps = {
  data?: Record<string, any>[]
}

export const FieldGridSingle: FC<FieldGridSingleProps> = ({data}) => {
  if (data == null) return <></>

  const [scoresData, setScoresData] = useState()
  const [sourcesData, setSourcesData] = useState()

  const handleOnRowSelected = (row) => {
    setScoresData(row['scores'])
    setSourcesData(row['sources'])
  }
  const handleOnSelectionModelChange = (gridSelectionModel: GridSelectionModel) => {
    const index = gridSelectionModel[0]
    const row = rows[index]
    if (row) {
      handleOnRowSelected(row)
    }
  }

  const name = data[0]['data']['name']
  const moduleName = data[0]['data']['module_name']
  const rows: GridRowModel[] = data.map((token, index) => {
    return {
      id: index,
      value: token['data']['value'],
      power: token['power'],
      weight: token['weight'],
      scores: token['scores'],
      sources: token['sources'],
      projectCount: getProjectCountFromSources(token['sources']),
    }
  })

  const columns: GridColDef[] = [
    {field: 'value', headerName: 'Value', flex: 1},
    {field: 'power', headerName: 'Power', flex: 1},
    {field: 'weight', headerName: 'Weight', flex: 1},
    {field: 'projectCount', headerName: 'Projects', flex: 1},
  ]

  const sortModel: GridSortModel = [{field: 'power', sort: 'desc'}]

  return (
    <Box>
      <h2>Variants: {name} - {moduleName}</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{sorting: {sortModel}}}
        onSelectionModelChange={(e) => {
          handleOnSelectionModelChange(e)
        }}
        {...dataGridDefaultProps} />
      <SourcesGrid data={sourcesData}/>
      <ScoresGrid data={scoresData}/>
    </Box>
  )
}