import React, {FC, useState} from 'react'
import {Select} from '@mui/material'

type ArtworkSelectorProps = {
  artwork?: string,
  setArtwork?: React.Dispatch<React.SetStateAction<string>>
  artworkList?: string[]
}

export const ArtworkSelector: FC<ArtworkSelectorProps> = (props) => {
  const [artwork, setArtwork] = ('artwork' in props && 'setArtwork' in props)
    ? [props.artwork, props.setArtwork]
    : useState('basics')

  const [artworkList] = ('artworkList' in props)
    ? [props.artworkList, null]
    : useState(['basics'])

  const handleChange = (event) => {
    ('setArtwork' in props)
      ? props.setArtwork(event.target.value)
      : setArtwork(event.target.value)
  }

  return (
    <Select
      native
      value={artwork}
      onChange={handleChange}
    >
      {artworkList.map((option, index) =>
        <option value={option} key={index}>
          {option}
        </option>
      )}
    </Select>
  )
}