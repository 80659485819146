import React, {FC, useEffect, useState} from 'react'
import {Autocomplete, CircularProgress, TextField} from '@mui/material'

import {useHistory} from 'react-router-dom'
import queryString from 'query-string'
import {usePrivateAPIClient} from '../components/APIClient'

type FilterFieldProps = {
  filter?: string
  label?: string
}

export const FilterField: FC<FilterFieldProps> = (props) => {
  const history = useHistory()

  const [open, setOpen] = React.useState(false)
  const [filter] = useState(props.filter || 'project')
  const [options, setOptions] = useState([])
  const loading = open && options.length === 0

  const parsed = queryString.parse(window.location.search)
  const [inputValue] = useState(parsed[filter])

  const handleNavigate = (optionName) => {
    const parsed = queryString.parse(window.location.search);
    (optionName != null)
      ? parsed[filter] = optionName
      : delete parsed[filter]
    history.replace({
      pathname: '/grid/1/',
      search: queryString.stringify(parsed)
    })
  }

  const apiClient = usePrivateAPIClient()

  useEffect(() => {
    if (filter === 'score') {
      setOptions([
        {'name': '3'},
        {'name': '2'},
        {'name': '1'},
        {'name': '0'},
        {'name': '-1'},
        {'name': '-2'},
        {'name': '-3'},
      ])
      return
    }
    if (filter === 'is_machine') {
      setOptions([
        {'name': '1'},
        {'name': '0'},
      ])
      return
    }

    let active = true

    if (!loading) {
      return null
    }

    (async () => {
      let url = ''
      let label_key = ''
      const value_key = ''
      switch (filter) {
        case 'project':
          url = `/projects/?page_size=${5000}`
          label_key = 'name'
          break
        case 'artist':
          url = `/users/?page_size=${5000}`
          label_key = 'username'
          break
      }
      try {
        const result = await apiClient.get(url)
        if (active) {
          const newOptions = result.data.results.map(x => ({
            'name': x[label_key]
          }))
          setOptions(newOptions)
        }
      } catch (error) {
        console.log(error)
      }
    })()

    return () => {
      active = false
    }
  }, [loading])

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(event, option) => {
        (option != null)
          ? handleNavigate(option.name)
          : handleNavigate(null)
      }}
      getOptionLabel={(option) => (
        typeof option === 'string' ? option : option.name
      )}
      options={options}
      loading={loading}
      value={inputValue}
      size={'small'}
      fullWidth
      autoHighlight
      blurOnSelect
      selectOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label || filter}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  )
}