import React, {FC, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Alert, Box, Button, Card, CardActions, CardContent, CardHeader, SxProps, TextField, Theme,} from '@mui/material'

import {useAppContext} from '../AppContext'
import {clearAuthorizationToken, setAuthorizationToken, usePublicAPIClient,} from '../components/APIClient'

const classes: Record<string, SxProps<Theme>> = {
  alert: {
    whiteSpace: 'pre-line',
  },
  login: {
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 2,
    paddingTop: 1,
  },
  card: {
    maxWidth: '550px',
    margin: 'auto',
  },
}

type LoginPageProps = {
  action?: string
}

export const LoginPage: FC<LoginPageProps> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const appContext = useAppContext()

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const {from} = location.state as Record<string, any> || {
    from: {
      pathname: '/',
    },
  }
  useEffect(() => {
    if (props.action === 'logout') {
      clearAuthorizationToken()
      appContext.setUser(null)
      history.replace({pathname: '/'})
    }
  }, [])

  function handleSubmit(event) {
    event.preventDefault()
    const apiClient = usePublicAPIClient()
    const fetchData = async () => {
      setError(null)
      setIsLoading(true)
      try {
        const result = await apiClient.post('/auth-token/', {
          'username': username,
          'password': password,
        })

        setAuthorizationToken(result.data.token)
        appContext.setUser({username})
        history.replace(from)
      } catch (error) {
        setError(error)
      }
      setIsLoading(false)
    }
    fetchData()
  }

  function getDisplayError(error) {
    switch (error.response.status) {
      case 400:
        if (error.response.data) {
          const message = []
          for (const key in error.response.data) {
            if (key === 'non_field_errors') {
              message.push(`${error.response.data[key]}`)
            } else {
              message.push(`${key.toUpperCase()}: ${error.response.data[key]}`)
            }
          }
          if (message.length > 0) return message.join('\n')
        }
    }
    return 'An error occurred.'
  }

  return (
    <form noValidate autoComplete="on" onSubmit={handleSubmit}>
      <Box sx={classes.login}>
        <Card raised sx={classes.card}>
          <CardHeader
            title={'Login'}
          />
          <CardContent>
            {error &&
            <Alert sx={classes.alert} severity="error">{getDisplayError(
              error)}</Alert>
            }

            <Box mb={1}>
              <TextField
                id="username"
                label="Username/email"
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box>
              <TextField
                id="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </Box>

          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" color="primary">
              Login
            </Button>
          </CardActions>
        </Card>
      </Box>
    </form>
  )
}