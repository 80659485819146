import React, {FC, useContext} from 'react'
import {Redirect, Route, RouteComponentProps, RouteProps, Switch} from 'react-router-dom'
import {AppContext} from '../AppContext'
import {LoginPage} from '../pages/LoginPage'
import {ArtworkPage} from '../pages/ArtworkPage'
import {ArtworkGridPage} from '../pages/ArtworkGridPage'
import {ColorPage} from '../pages/ColorPage'
import {InsightPage} from '../pages/InsightPage'
import {Loading} from './Loading'
import queryString from 'query-string'
import {RandomVotePage} from '../pages/RandomVotePage'

export const Router: FC = () => {
  const appContext = useContext(AppContext)

  function PrivateRoute({children, ...rest}: RouteProps) {
    return (
      <Route
        {...rest}
        render={({location}) =>
          appContext.user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {from: location},
              }}
            />
          )
        }
      />
    )
  }

  if (!appContext.ready) {
    return (
      <Loading/>
    )
  }

  return (
    <Switch>
      <Route path={'/login'}>
        <LoginPage/>
      </Route>
      <Route path={'/logout'}>
        <LoginPage action={'logout'}/>
      </Route>
      <PrivateRoute path={'/color'}>
        <ColorPage key={'color'}/>
      </PrivateRoute>
      <PrivateRoute path={'/insight'}>
        <InsightPage key={'insight'}/>
      </PrivateRoute>
      <PrivateRoute path={'/grid/:page?/'} component={(props: RouteComponentProps) => (
        <ArtworkGridPage
          key={`grid-${props.match.params['page']}-${props.location.search}`}
          page={props.match.params['page'] || 1}
          searchParams={queryString.parse(props.location.search)}
          path={'/grid/:page/'}
        />
      )}/>
      <PrivateRoute path={'/voting'}>
        <RandomVotePage key={'voting'}/>
      </PrivateRoute>
      <PrivateRoute path={'/unseen'}>
        <ArtworkPage key={'unseen'} filter={'unseen'}/>
      </PrivateRoute>
      <Route exact path="/">
        <Redirect to="/grid"/>
      </Route>
    </Switch>
  )
}