import React, {FC, useState} from 'react'
import {DataGrid, GridColDef, GridRowModel, GridSelectionModel, GridSortModel} from '@mui/x-data-grid'
import {Box} from '@mui/material'
import {ScoresGrid} from './ScoresGrid'
import {SourcesGrid} from './SourcesGrid'
import {dataGridDefaultProps, getProjectCountFromSources} from './utils'
import {JsonData} from './JsonData'

type ProcessorGridSingleProps = {
  data?: Record<string, any>[]
}

export const ProcessorGridSingle: FC<ProcessorGridSingleProps> = ({data}) => {
  if (data == null) return <></>

  const [scoresData, setScoresData] = useState()
  const [sourcesData, setSourcesData] = useState()
  const [jsonData, setJsonData] = useState<string>()

  const handleOnRowSelected = (row) => {
    setScoresData(row['scores'])
    setSourcesData(row['sources'])
    setJsonData(formatJsonDataFromFields(row['modulesRaw']))
  }
  const handleOnSelectionModelChange = (gridSelectionModel: GridSelectionModel) => {
    const index = gridSelectionModel[0]
    const row = rows[index]
    if (row) {
      handleOnRowSelected(row)
    }
  }

  const formatJsonDataFromFields = (modules) => {
    const moduleAr = modules.map((module) => {
      const fieldDict = {}
      module['fields'].reduce(
        (acc, field) => {
          acc[field['name']] = field['value']
          return acc
        },
        fieldDict
      )
      return {
        name: module['name'],
        fields: fieldDict,
      }
    })

    return JSON.stringify(moduleAr, null, 2)
  }

  const name = data[0]['data']['name']
  const rows: GridRowModel[] = data.map((token, index) => {
    return {
      id: index,
      modules: token['data']['modules'].map((module) => module['name']).join(', '),
      moduleCount: token['data']['modules'].length,
      power: token['power'],
      weight: token['weight'],
      scores: token['scores'],
      sources: token['sources'],
      projectCount: getProjectCountFromSources(token['sources']),
      modulesRaw: token['data']['modules'],
    }
  })

  const columns: GridColDef[] = [
    {field: 'modules', headerName: 'Modules', flex: 2},
    {field: 'moduleCount', headerName: 'Modules count', flex: 1},
    {field: 'power', headerName: 'Power', flex: 1},
    {field: 'weight', headerName: 'Weight', flex: 1},
    {field: 'projectCount', headerName: 'Projects', flex: 1},
  ]

  const sortModel: GridSortModel = [{field: 'power', sort: 'desc'}]

  return (
    <Box>
      <h2>Variants: {name}</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{sorting: {sortModel}}}
        onSelectionModelChange={(e) => {
          handleOnSelectionModelChange(e)
        }}
        {...dataGridDefaultProps} />
      <JsonData data={jsonData}/>
      <SourcesGrid data={sourcesData}/>
      <ScoresGrid data={scoresData}/>
    </Box>
  )
}