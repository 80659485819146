import React, {createContext, useContext, useEffect, useState} from 'react'
import {isAuthorizationTokenSet, usePrivateAPIClient,} from './components/APIClient'

// https://reactjs.org/docs/context.html#updating-context-from-a-nested-component
export type AppContextProps = {
  user?: Record<string, any>,
  setUser?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  ready: boolean,
  setReady?: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = createContext<AppContextProps>({
  ready: false
})

export const useProvideAppContext = () => {
  const [user, setUser] = useState(null)
  const [ready, setReady] = useState(false)
  return {
    user,
    setUser,
    ready,
    setReady,
  }
}

export const useAppContext = () => {
  return useContext(AppContext)
}

type Props = {
  children: JSX.Element
}

export const ProvideAppContext = ({children}: Props) => {
  const appContext = useProvideAppContext()
  useEffect(() => {
    if (appContext.user === null && isAuthorizationTokenSet()) {
      const apiClient = usePrivateAPIClient()
      const fetchData = async () => {
        try {
          const result = await apiClient.get('/me/')
          appContext.setUser(result.data)
        } catch (error) {
          appContext.setUser(null)
        }
        appContext.setReady(true)
      }
      fetchData()
    } else {
      appContext.setReady(true)
    }
  })
  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  )
}