import React, {FC, useEffect, useState} from 'react'
import {Box, Button, ButtonGroup, Grid, Stack, ToggleButton, ToggleButtonGroup} from '@mui/material'

import {usePrivateAPIClient} from '../components/APIClient'
import {ArtworkVoting, ArtworkVotingSkeleton} from '../artwork/ArtworkVoting'

export const RandomVotePage: FC = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<Record<string, any>>({})
  const [voteType, setVoteType] = useState('all')
  const [voteScore, setVoteScore] = useState('1')
  const pageSize = 6

  const apiClient = usePrivateAPIClient()
  const fetchData = async () => {
    setError(null)
    setIsLoading(true)
    try {
      let url = `/artworks/randomvote/?page_size=${pageSize}`
      if (voteType != 'all') {
        url += `&type=${voteType}`
      }
      const result = await apiClient.get(url)
      setData(result.data)
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  const postData = async (artworkId) => {
    setError(null)
    setIsLoading(true)
    const body = {}
    if (voteType === 'all' || voteType === 'score') {
      body['score'] = voteScore
    } else {
      const normalizedScore = parseInt(voteScore, 10) > 0 ? 1 : -1
      body[`${voteType}_score`] = normalizedScore
    }
    // API will automatically update or create based on artwork id and logged user.
    try {
      body['artwork'] = artworkId
      await apiClient.post(
        '/feedbacks/',
        body,
      )
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleArtworkClick = async (artwork: Record<string, any>) => {
    console.log(artwork)
    await postData(artwork.id)
    fetchData()
  }
  const handleTypeChange = (event, newType) => {
    setVoteType(newType)
  }
  const handleScoreChange = (event, newScore) => {
    setVoteScore(newScore)
  }
  const handleSkipClick = (event) => {
    fetchData()
  }


  if (error) {
    return <div>Error: {error.message}</div>
  } else if (isLoading) {
    return (
      <Box>
        <Grid container spacing={0}>
          {[...Array(6)].map((x, index) =>
            <Grid container item xs={6} md={4} key={index}>
              <ArtworkVotingSkeleton key={index}/>
            </Grid>
          )}
        </Grid>
      </Box>
    )
  } else if (data.count === 0) {
    return <div>No result.</div>
  } else {
    return (
      <Box>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          spacing={{xs: 1, sm: 2}}
        >
          <Box>
            <ToggleButtonGroup
              value={voteType}
              onChange={handleTypeChange}
              exclusive
              size="small"
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="score">
                <span className="material-icons">bar_chart</span>
              </ToggleButton>
              <ToggleButton value="color">
                <span className="material-icons">palette</span>
              </ToggleButton>
              <ToggleButton value="count">
                <span className="material-icons">numbers</span>
              </ToggleButton>
              <ToggleButton value="location">
                <span className="material-icons">location_on</span>
              </ToggleButton>
              <ToggleButton value="rotation">
                <span className="material-icons">rotate_left</span>
              </ToggleButton>
              <ToggleButton value="scale">
                <span className="material-icons">straighten</span>
              </ToggleButton>
              <ToggleButton value="shadow">
                <span className="material-icons">filter_none</span>
              </ToggleButton>
              <ToggleButton value="shape">
                <span className="material-icons">interests</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            <ToggleButtonGroup
              value={voteScore}
              onChange={handleScoreChange}
              exclusive
              size="small"
            >
              <ToggleButton value="-2">
                <span className="material-icons">not_interested</span>
              </ToggleButton>
              <ToggleButton value="2">
                <span className="material-icons">star</span>
              </ToggleButton>
              <ToggleButton value="-1">
                <span className="material-icons">thumb_down_alt</span>
              </ToggleButton>
              <ToggleButton value="1">
                <span className="material-icons">thumb_up_alt</span>
              </ToggleButton>
            </ToggleButtonGroup>
            <ButtonGroup
              sx={{
                marginLeft: 2
              }}
            >
              <Button onClick={handleSkipClick}>
                <span className="material-icons">cached</span>
              </Button>
            </ButtonGroup>
          </Box>
        </Stack>
        <Grid container spacing={0}>
          {data.results.map((artwork, index) =>
            <Grid container item xs={6} md={4} key={artwork.id}>
              <ArtworkVoting
                key={artwork.id}
                artwork={artwork}
                onClick={handleArtworkClick}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    )
  }
}