import React, {FC, useState} from 'react'
import {TextField} from '@mui/material'

type CommentFormProps = {
  comment?: string,
  setComment?: React.Dispatch<React.SetStateAction<string>>
}

export const CommentForm: FC<CommentFormProps> = (props) => {
  const [comment, setComment] = ('comment' in props && 'setComment' in props) ?
    [props.comment, props.setComment] :
    useState('')

  const handleComment = (event) => {
    const newComment = event.target.value;
    ('setComment' in props) ?
      props.setComment(newComment) :
      setComment(newComment)
  }

  return (
    <div>
      <TextField
        id="comment"
        label="Comment"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={comment}
        onChange={handleComment}
      />
    </div>
  )
}