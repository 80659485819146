import React, {FC} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {StyledEngineProvider, ThemeProvider,} from '@mui/material/styles'
import {Box, CssBaseline} from '@mui/material'

import {ProvideAppContext} from './AppContext'
import {Router} from './components/Router'
import {NavBar} from './navigation/NavBar'
import {theme} from './components/Theme'

export const App: FC = () => {
  return (
    <ProvideAppContext>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{
              position: 'relative',
              paddingBottom: {
                xs: 10,
                sm: 12
              }
            }}>
              <Router/>
              <NavBar/>
            </Box>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </ProvideAppContext>
  )
}