import React, {FC, useState} from 'react'
import {SvgCard} from '../color/SvgCard'
import {ArtworkSelector} from '../color/ArtworkSelector'
import {Toolbar} from '../color/Toolbar'
import {Box, SxProps, Theme} from '@mui/material'

const classes: Record<string, SxProps<Theme>> = {
  content: {
    flexGrow: 1,
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 2,
    paddingTop: 1,
  },
  selector: {
    ['@media print']: {
      display: 'none',
    },
  },
}

type ColorPageProps = {
  colors?: string[],
  setColors?: React.Dispatch<React.SetStateAction<string[]>>
}

export const ColorPage: FC<ColorPageProps> = (props) => {
  const [artwork, setArtwork] = useState('basics')

  const [colors, setColors] = ('colors' in props && 'setColors' in props)
    ? [props.colors, props.setColors]
    : useState([])

  const artworkList = [
    'basics',
    'fireworks',
    'gummy',
    'happy',
    'montreal',
    'pouliche',
    'sail',
    'three',
    'valley',
  ]

  return (
    <Box sx={{display: 'flex'}}>
      <Box component={'main'} sx={classes.content}>
        <Box sx={classes.selector}>
          <ArtworkSelector
            artwork={artwork}
            setArtwork={setArtwork}
            artworkList={artworkList}
          />
        </Box>
        <SvgCard
          artwork={artwork}
          colors={colors}
        />
      </Box>
      <Toolbar
        colors={colors}
        setColors={setColors}
      />
    </Box>
  )
}