import React, {FC} from 'react'
import {Button, ButtonGroup, Typography} from '@mui/material'

type PaletteSwatchesProps = {
  id?: string,
  name?: string,
  colors?: string[]
  onClick?: (data: Record<string, any>) => void
}

export const PaletteSwatches: FC<PaletteSwatchesProps> = (props) => {
  const id = props.id || null
  const name = props.name || ''
  const colors = props.colors || []
  const onClick = props.onClick || null

  const handleClick = () => {
    if (onClick != null && (typeof onClick) == 'function') {
      onClick({
        'id': id,
        'name': name,
        'colors': colors
      })
    }
  }

  return (
    <div>
      <ButtonGroup disableElevation variant="contained">
        {colors.map((color, index) =>
          <Button
            size="small"
            sx={{
              background: color
            }}
            onClick={handleClick}
            key={index}
          />
        )}
      </ButtonGroup>
      <Typography variant="subtitle2">
        {name}
      </Typography>
    </div>
  )

}