import React, {FC} from 'react'
import {Box, Button, Card, CardContent} from '@mui/material'

type JsonDataProps = {
  data?: string
}

export const JsonData: FC<JsonDataProps> = ({data}) => {
  if (data == null) return <></>

  const handleCopy = () => {
    navigator.clipboard.writeText(data)
  }

  return (
    <Box mt={2}>
      <Card sx={{position: 'relative'}}>
        <Button
          size="small"
          variant="contained"
          onClick={(e) => handleCopy()}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
        >
          Copy
        </Button>
        <CardContent sx={{pt: 0}}>
          <pre style={{margin: 0}}>
            {data}
          </pre>
        </CardContent>
      </Card>
    </Box>
  )
}