import React, {FC, useState} from 'react'
import {SxProps, Theme, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {theme} from '../components/Theme'

const classes: Record<string, SxProps<Theme>> = {
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: 1,
    },
    '&:first-child': {
      borderRadius: 1,
    },
  },
}

type ExtraScoreToggleProps = {
  score?: number,
  setScore?: React.Dispatch<React.SetStateAction<number>>
}

export const ExtraScoreToggle: FC<ExtraScoreToggleProps> = (props) => {
  const [score, setScore] = ('score' in props && 'setScore' in props) ?
    [props.score, props.setScore] :
    useState(0)

  const handleScore = (event, newScore) => {
    ('setScore' in props) ?
      props.setScore(newScore) :
      setScore(newScore)
  }

  return (
    <ToggleButtonGroup
      value={score}
      exclusive
      onChange={handleScore}
      sx={{
        '& .MuiToggleButtonGroup-grouped': classes.grouped
      } as SxProps}
    >
      <ToggleButton value={-1}>
        <span className="material-icons">thumb_down_alt</span>
      </ToggleButton>
      <ToggleButton value={0}>
        OK
      </ToggleButton>
      <ToggleButton value={1}>
        <span className="material-icons">thumb_up_alt</span>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}