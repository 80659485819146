import React, {FC, useContext, useState} from 'react'
import {Divider, Drawer, Fab, List, ListItem, ListItemText,} from '@mui/material'
import {AppContext} from '../AppContext'
import {useHistory} from 'react-router-dom'
import {FilterField} from './FilterField'
import {theme} from '../components/Theme'

export const NavBar: FC = () => {
  const appContext = useContext(AppContext)
  const history = useHistory()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleNavigate = (pathname) => {
    handleDrawerClose()
    history.replace({pathname: pathname})
  }

  const PublicList = () => (
    <List>
      <ListItem button key={'Login'}>
        <ListItemText primary={'Login'}
          onClick={() => handleNavigate('/login')}/>
      </ListItem>
    </List>
  )

  const PrivateList = () => (
    <List>
      <ListItem button key={'Unseen'} onClick={() => handleNavigate('/unseen')}>
        <ListItemText primary={'Unseen'}/>
      </ListItem>
      <ListItem button key={'Voting'} onClick={() => handleNavigate('/voting')}>
        <ListItemText primary={'Voting'}/>
      </ListItem>
      <Divider/>
      <ListItem button key={'Latest'} onClick={() => handleNavigate('/grid')}>
        <ListItemText primary={'Latest'}/>
      </ListItem>
      <ListItem button key={'filter-project'}>
        <FilterField filter={'project'} label={'Project name'}/>
      </ListItem>
      <ListItem button key={'filter-score'}>
        <FilterField filter={'score'} label={'Score'}/>
      </ListItem>
      <ListItem button key={'filter-is_machine'}>
        <FilterField filter={'is_machine'} label={'Is Machine'}/>
      </ListItem>
      <ListItem button key={'filter-artist'}>
        <FilterField filter={'artist'} label={'Artist name'}/>
      </ListItem>
      <Divider/>
      <ListItem button key={'color'} onClick={() => handleNavigate('/color')}>
        <ListItemText primary={'Color'}/>
      </ListItem>
      <Divider/>
      <ListItem button key={'insight'}
        onClick={() => handleNavigate('/insight')}>
        <ListItemText primary={'Insight'}/>
      </ListItem>
      <Divider/>
      <ListItem button key={'Logout'} onClick={() => handleNavigate('/logout')}>
        <ListItemText primary={'Logout'}/>
      </ListItem>
    </List>
  )

  return (
    <div>
      <Fab
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          left: theme.spacing(2),
        }}
        onClick={handleDrawerOpen}
      >
        <span className="material-icons">menu</span>
      </Fab>
      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {width: '240px'}
        }}
      >
        {appContext.user
          ? <PrivateList/>
          : <PublicList/>
        }
      </Drawer>
    </div>
  )
}