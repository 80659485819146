import React, {FC, useState} from 'react'
import {Box, Button, IconButton} from '@mui/material'

type ColorSwatchProps = {
  color?: string,
  onColorClick?: () => void
  onDeleteClick?: () => void
}

export const ColorSwatch: FC<ColorSwatchProps> = (props: ColorSwatchProps) => {
  const [color, setColor] = ('color' in props)
    ? [props.color, null]
    : useState('#000000')

  const handleColorClick = () => {
    if (
      'onColorClick' in props
      && (typeof props.onColorClick) == 'function'
    ) {
      props.onColorClick()
    }
  }

  const handleDeleteClick = () => {
    if (
      'onDeleteClick' in props
      && (typeof props.onDeleteClick) == 'function'
    ) {
      props.onDeleteClick()
    }
  }

  return (
    <div>
      <Button
        sx={{
          background: color,
        }}
        onClick={handleColorClick}
      />
      <IconButton onClick={handleDeleteClick} size="large">
        <span className="material-icons">delete</span>
      </IconButton>
      <Box sx={{
        display: 'inline-flex',
        flex: '0 0 auto',
        padding: '12px',
        verticalAlign: 'middle',
      }}>
        <span className="material-icons">drag_indicator</span>
      </Box>
    </div>
  )
}