import React, {FC, useEffect, useState} from 'react'
import {IconButton, TextField, Tooltip} from '@mui/material'
import {usePrivateAPIClient} from '../components/APIClient'
import {parseString} from 'xml2js'

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16),
  } : null
}

const hexToRgbFloat = (hex) => {
  const rgb = hexToRgb(hex)
  if (rgb) {
    return {
      red: (rgb.red > 0) ? rgb.red / 255 : 0,
      green: (rgb.green > 0) ? rgb.green / 255 : 0,
      blue: (rgb.blue > 0) ? rgb.blue / 255 : 0,
    }
  }
}

const rgbToHex = (r, g, b) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

const rgbFloatToHex = (r, g, b) => {
  return rgbToHex(
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255),
  )
}

// https://stackoverflow.com/a/30800715
const downloadObjectAsJson = (exportObj, exportName) => {
  const dataStr = 'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(exportObj))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', exportName + '.palette')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

type ColorActionsProps = {
  colors?: string[]
  setColors?: React.Dispatch<React.SetStateAction<string[]>>
  paletteId?: string,
  setPaletteId?: React.Dispatch<React.SetStateAction<string>>
  paletteName?: string,
  setPaletteName?: React.Dispatch<React.SetStateAction<string>>,
  onPaletteCreate?: (data: Record<string, any>) => void
  onPaletteUpdate?: (data: Record<string, any>) => void
  onPaletteDelete?: (data: Record<string, any>) => void
}

export const ColorActions: FC<ColorActionsProps> = (props) => {
  const [colors, setColors] = (
    'colors' in props
    && 'setColors' in props
  )
    ? [props.colors, props.setColors]
    : useState([])

  const [paletteId, setPaletteId] = (
    'paletteId' in props
    && 'setPaletteId' in props
  )
    ? [props.paletteId, props.setPaletteId]
    : useState(null)

  const [paletteName, setPaletteName] = (
    'paletteName' in props
    && 'setPaletteName' in props
  )
    ? [props.paletteName, props.setPaletteName]
    : useState('')

  const [paletteNameError, setPaletteNameError] = useState(false)
  useEffect(() => {
    if (paletteName != null && paletteName !== '') {
      setPaletteNameError(false)
    }
  }, [paletteName])

  const handleAddClick = () => {
    const tmpColors = [...colors]
    tmpColors.push('#CCCCCC')
    setColors(tmpColors)
  }

  const handleClearClick = () => {
    resetView()
  }

  const handleDeleteClick = () => {
    if (window.confirm('Ok, DELETE from server?')) {
      deleteData()
    }
  }

  const handleMakeCopyClick = () => {
    setPaletteName('')
    setPaletteId(null)
  }

  const handleSaveClick = () => {
    if (paletteName == null || paletteName === '') {
      setPaletteNameError(true)
    } else {
      postData()
    }
  }

  const handlePasteFromClipboardClick = () => {
    pasteFromClipboard()
  }

  const handleDownloadPaletteClick = () => {
    downloadPalette()
  }

  const pasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText()
    console.log(text)
    parseString(text, (err, res) => {
      if (err == null) {
        console.log(res)
        if (
          'palette' in res
          && 'color' in res['palette']
          && Array.isArray(res['palette']['color'])
        ) {
          let colors = res['palette']['color'].map((color) => {
            if ('$' in color && 'rgb' in color['$']) {
              return `#${color['$']['rgb']}`
            }
          })
          colors = colors.filter((color) => color != null)
          setColors(colors)
        }
      }
    })
  }

  const handleFileInput = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      try {
        const result = JSON.parse(e.target.result as string)
        const tmpColors = result.colors.map((palette) => {
          return rgbFloatToHex(palette.red, palette.green, palette.blue)
        })
        setColors(tmpColors)
      } catch (error) {
        console.log(error)
      }
    }
    reader.readAsText(file)
  }

  const downloadPalette = () => {
    const data = {
      colors: colors.map((color) => {
        const data = hexToRgbFloat(color)
        data['name'] = color
        return data
      }),
    }
    downloadObjectAsJson(data, paletteName)
  }

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const apiClient = usePrivateAPIClient()
  const postData = async () => {
    setError(null)
    setIsLoading(true)
    try {
      let url, result
      const data = {
        'name': paletteName,
        'colors': colors,
      }
      if (paletteId == null) {
        url = '/palettes/'
        result = await apiClient.post(url, data)
        if ('id' in result.data) {
          setPaletteId(result.data['id'])
          if (
            'onPaletteCreate' in props
            && (typeof props.onPaletteCreate) == 'function'
          ) {
            props.onPaletteCreate({
              id: result.data['id'],
              name: result.data['name'],
              colors: result.data['colors'],
            })
          }
        }
      } else {
        url = `/palettes/${paletteId}/`
        result = await apiClient.put(url, data)
        if (
          'onPaletteUpdate' in props
          && (typeof props.onPaletteUpdate) == 'function'
        ) {
          props.onPaletteUpdate({
            id: result.data['id'],
            name: result.data['name'],
            colors: result.data['colors'],
          })
        }
      }
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  const deleteData = async () => {
    setError(null)
    setIsLoading(true)
    try {
      const url = `/palettes/${paletteId}/`
      const result = await apiClient.delete(url)
      if (
        'onPaletteDelete' in props
        && (typeof props.onPaletteDelete) == 'function'
      ) {
        props.onPaletteDelete({
          id: paletteId,
        })
      }
      resetView()
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  const resetView = () => {
    setPaletteId(null)
    setPaletteName('')
    setColors([])
    setError(null)
  }

  const buttonSize = 'small'

  return (
    <div>
      <div>
        <TextField
          fullWidth
          onChange={(event) => setPaletteName(event.target.value)}
          error={paletteNameError}
          value={paletteName}
        />
      </div>
      <div>
        {colors.length > 0 &&
        <Tooltip title="Restart">
          <IconButton
            size={buttonSize}
            onClick={handleClearClick}
          >
            <span className="material-icons">clear</span>
          </IconButton>
        </Tooltip>
        }

        <Tooltip title="Add swatch">
          <IconButton
            size={buttonSize}
            onClick={handleAddClick}
          >
            <span className="material-icons">add_circle</span>
          </IconButton>
        </Tooltip>

        {colors.length > 0 &&
        <Tooltip
          title={paletteId ? 'Update saved palette' : 'Save new palette'}>
          <IconButton
            size={buttonSize}
            onClick={handleSaveClick}
          >
            <span className="material-icons">
              {paletteId ? 'save' : 'save_as'}
            </span>
          </IconButton>
        </Tooltip>
        }

        {paletteId &&
        <Tooltip title="Make new copy">
          <IconButton
            size={buttonSize}
            onClick={handleMakeCopyClick}
          >
            <span className="material-icons">content_copy</span>
          </IconButton>
        </Tooltip>
        }

        {paletteId &&
        <Tooltip title="Delete saved palette">
          <IconButton
            size={buttonSize}
            onClick={handleDeleteClick}
          >
            <span className="material-icons">delete_outline</span>
          </IconButton>
        </Tooltip>
        }

        <Tooltip title="Paste from Adobe Color">
          <IconButton
            size={buttonSize}
            onClick={handlePasteFromClipboardClick}
          >
            <span className="material-icons">content_paste_go</span>
          </IconButton>
        </Tooltip>

        <Tooltip title="Import palette">
          <IconButton
            size={buttonSize}
            component="label"
          >
            <span className="material-icons">file_upload</span>
            <input type="file"
              hidden
              onChange={(e) => handleFileInput(e.target.files[0])}/>
          </IconButton>
        </Tooltip>

        {colors.length > 0 &&
        <Tooltip title="Download palette">
          <IconButton
            size={buttonSize}
            onClick={handleDownloadPaletteClick}
          >
            <span className="material-icons">download</span>
          </IconButton>
        </Tooltip>
        }

      </div>
      {error &&
      <div>{error}</div>
      }
      {isLoading &&
      <div>Saving...</div>
      }
    </div>

  )

}