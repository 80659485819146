import React, {FC, useEffect, useState} from 'react'
import {groupBy} from 'lodash'
import {FieldGrid} from '../insight/FieldGrid'
import {ModuleGrid} from '../insight/ModuleGrid'
import {ProcessorGrid} from '../insight/ProcessorGrid'
import {ProcessorSignatureGrid} from '../insight/ProcessorSignatureGrid'
import {Box, Tab} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'

export const InsightPage: FC = () => {
  const [tabValue, setTabValue] = useState('0')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const [insightData, setInsightData] = useState<Record<string, any>>()
  const [isLoading, setIsLoading] = useState(false)

  const [processorSignatureData, setProcessorSignatureData] = useState()
  const [processorData, setProcessorData] = useState()
  const [moduleData, setModuleData] = useState()
  const [fieldData, setFieldData] = useState()
  // const [emitterData, setEmitterData] = useState()

  const handleFileInput = (file) => {
    setIsLoading(true)
    const reader = new FileReader()
    reader.onload = (e) => {
      setIsLoading(false)
      try {
        const result = JSON.parse(e.target.result as string)
        decompressInsightData(result)
        setInsightData(result)
      } catch (e) {
        console.log(e)
      }
    }
    reader.readAsText(file)
  }

  // See decompress_sources() in @generativeart/src/learner/models.py
  const decompressInsightData = (data) => {
    if ('sources_lookup' in data && data['sources_lookup'].length > 0) {
      const sourceLookup = data['sources_lookup']
      const tokenizedLists = [
        data['tokenized_processor_signatures'],
        data['tokenized_processors'],
        data['tokenized_modules'],
        data['tokenized_fields'],
      ]
      tokenizedLists.forEach((tokenizedList) => {
        tokenizedList.forEach((tokenized) => {
          if (tokenized['sources'].length === 1) {
            const sourceLookups = tokenized['sources'][0].split(':')
            const sourceLookupIndexes = sourceLookups.map((sourceIndex) => parseInt(sourceIndex, 10))
            tokenized['sources'] = sourceLookupIndexes.map(
              (sourceIndex) => getSourceFromLookupIndex(sourceIndex, sourceLookup)
            ).filter((source) => source !== '')
          }
          if (tokenized['scores']['config_file'].length === 1) {
            const sourceLookups = tokenized['scores']['config_file'][0].split(':')
            const sourceLookupIndexes = sourceLookups.map((sourceIndex) => parseInt(sourceIndex, 10))
            tokenized['scores']['config_file'] = sourceLookupIndexes.map(
              (sourceIndex) => getSourceFromLookupIndex(sourceIndex, sourceLookup)
            ).filter((source) => source !== '')
          }
        })
      })
    }
  }
  const getSourceFromLookupIndex = (index, sourceLookup) => {
    try {
      return sourceLookup[index]
    } catch (e) {
      return ''
    }
  }

  const prepareDataTokenizedProcessorSignatures = (data) => {
    return groupBy(data, (field) => field['data']['name'])
  }
  const prepareDataTokenizedProcessors = (data) => {
    return groupBy(data, (field) => field['data']['name'])
  }
  const prepareDataTokenizedModules = (data) => {
    return groupBy(data, (field) => field['data']['name'])
  }
  const prepareDataTokenizedFields = (data) => {
    return groupBy(
      data,
      (field) => `${field['data']['module_name']}-${field['data']['name']}`
    )
  }

  useEffect(() => {
    if (insightData) {
      const _processorSignatureData = prepareDataTokenizedProcessorSignatures(insightData['tokenized_processor_signatures'])
      setProcessorSignatureData(_processorSignatureData)
      const _processorData = prepareDataTokenizedProcessors(insightData['tokenized_processors'])
      setProcessorData(_processorData)
      const _moduleData = prepareDataTokenizedModules(insightData['tokenized_modules'])
      setModuleData(_moduleData)
      const _fieldData = prepareDataTokenizedFields(insightData['tokenized_fields'])
      setFieldData(_fieldData)
    }
  }, [insightData])

  return (
    <div>
      <h1>Insight</h1>
      <input type="file" onChange={(e) => handleFileInput(e.target.files[0])}/>
      {insightData && (
        <Box>
          <hr/>
          <TabContext value={tabValue}>
            <TabList onChange={handleTabChange}>
              <Tab label="Processor Signatures" value="0"/>
              <Tab label="Processors" value="1"/>
              <Tab label="Modules" value="2"/>
              <Tab label="Fields" value="3"/>
            </TabList>
            <TabPanel value="0">
              <ProcessorSignatureGrid data={processorSignatureData}/>
            </TabPanel>
            <TabPanel value="1">
              <ProcessorGrid data={processorData}/>
            </TabPanel>
            <TabPanel value="2">
              <ModuleGrid data={moduleData}/>
            </TabPanel>
            <TabPanel value="3">
              <FieldGrid data={fieldData}/>
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </div>
  )
}