import React, {createRef, FC, useEffect, useState} from 'react'
import SwipeableViews from 'react-swipeable-views'

import {Artwork, ArtworkSkeleton} from '../artwork/Artwork'

import {usePrivateAPIClient} from '../components/APIClient'
import {virtualize} from 'react-swipeable-views-utils'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

type ArtworkPageProps = {
  filter?: string,
  score?: number,
  projectName?: string,
  artistName?: string
}

export const ArtworkPage: FC<ArtworkPageProps> = (props) => {
  let childRefs = {}

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<Record<string, any>>({})

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [paginationDirection, setPaginationDirection] = useState<number>(1)
  const [filter] = useState<string>(props.filter || 'latest')
  const [score] = useState<number>(props.score || 0)
  const [projectName] = useState<string>(props.projectName || '')
  const [artistName] = useState<string>(props.artistName || '')

  const [slideIndex, setSlideIndex] = useState<number>(0)
  const [slideCount, setSlideCount] = useState<number>(pageSize)

  const apiClient = usePrivateAPIClient()
  const fetchData = async () => {
    childRefs = {}
    setError(null)
    setIsLoading(true)
    try {
      let url = ''
      switch (filter) {
        case 'latest':
          url = `/artworks/?page=${page}&page_size=${pageSize}`
          break
        case 'unseen':
          url = `/artworks/unseen/?page=1&page_size=${pageSize}`
          break
        case 'score':
          url = `/artworks/?score=${score}&page=${page}&page_size=${pageSize}&ordering=-feedbacks__created_at`
          break
        case 'project':
          url = `/artworks/?project_name=${projectName}&page=${page}&page_size=${pageSize}`
          break
        case 'artist':
          url = `/artworks/?artist_name=${artistName}&page=${page}&page_size=${pageSize}`
          break
      }
      const result = await apiClient.get(url)
      setData(result.data)

      if (result.data.count > 0) {
        const tmpPageSize = result.data.pagination.size
        setPageSize(tmpPageSize)

        const remainingCount = result.data.count -
          ((result.data.pagination.current - 1) * tmpPageSize)
        let tmpSlideCount = (remainingCount >= tmpPageSize)
          ? tmpPageSize
          : remainingCount
        if (result.data.pagination.previous) tmpSlideCount++
        if (result.data.pagination.next) tmpSlideCount++
        setSlideCount(tmpSlideCount)

        const tmpSlideIndex = (paginationDirection > 0) ?
          ((result.data.pagination.previous) ? 1 : 0) :
          ((result.data.pagination.next) ? tmpSlideCount - 2 : tmpSlideCount -
            1)
        setSlideIndex(tmpSlideIndex)
      }
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page, filter]) // https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect

  const handleChangeIndex = (index, indexLatest, meta) => {
    setSlideIndex(index)
    for (const key in childRefs) {
      if (childRefs[key].current != null) {
        childRefs[key].current.onSlideIndexChange(index, indexLatest)
      }
    }
  }

  const handleTransitionEnd = () => {
    if (slideIndex === 0 && data.pagination.previous) {
      setPaginationDirection(-1)
      setPage(data.pagination.previous)
    }
    if (slideIndex === slideCount - 1 && data.pagination.next) {
      setPaginationDirection(1)
      setPage(data.pagination.next)
    }
  }

  const slideRenderer = (params) => {
    const {index, key} = params
    const artwork_index = (data.pagination.previous) ? index - 1 : index
    const artwork = data.results[artwork_index]
    if (data.pagination.previous && index === 0) {
      return <ArtworkSkeleton key={'previous'}/>
    } else if (data.pagination.next && index === slideCount - 1) {
      return <ArtworkSkeleton key={'next'}/>
    } else {
      const childRef = createRef()
      childRefs[index] = childRef
      return <Artwork ref={childRef} key={artwork.id} artwork={artwork}
        slideIndex={index}/>
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (isLoading) {
    return <ArtworkSkeleton/>
  } else if (data.count === 0) {
    return <div>No result.</div>
  } else {
    return (
      <VirtualizeSwipeableViews
        enableMouseEvents
        index={slideIndex}
        onChangeIndex={handleChangeIndex}
        onTransitionEnd={handleTransitionEnd}
        slideRenderer={slideRenderer}
        slideCount={slideCount}
      />
    )
  }
}