import React, {FC, useState} from 'react'
import {DataGrid, GridColDef, GridRowModel, GridSelectionModel, GridSortModel} from '@mui/x-data-grid'
import {Box} from '@mui/material'
import {dataGridDefaultProps} from './utils'
import {ProcessorGridSingle} from './ProcessorGridSingle'

type ProcessorGridProps = {
  data?: Record<string, any>
}

export const ProcessorGrid: FC<ProcessorGridProps> = ({data}) => {
  if (data == null) return <></>

  const [subData, setSubData] = useState()

  const handleOnRowSelected = (row) => {
    setSubData(row['data'])
  }
  const handleOnSelectionModelChange = (gridSelectionModel: GridSelectionModel) => {
    const index = gridSelectionModel[0]
    const row = rows[index]
    if (row) {
      handleOnRowSelected(row)
    }
  }

  const rows: GridRowModel[] = []
  let index = 0
  for (const key in data) {
    rows.push({
      id: index,
      name: data[key][0]['data']['name'],
      count: data[key].length,
      data: data[key],
    })
    index++
  }

  const columns: GridColDef[] = [
    {field: 'name', headerName: 'Name', flex: 1},
    {field: 'count', headerName: 'Variants', flex: 1},
  ]

  const sortModel: GridSortModel = [{field: 'name', sort: 'asc'}]

  return (
    <Box>
      <h2>Processors</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        initialState={{sorting: {sortModel}}}
        onSelectionModelChange={(e) => {
          handleOnSelectionModelChange(e)
        }}
        {...dataGridDefaultProps} />
      {subData && (
        <ProcessorGridSingle data={subData}/>
      )}
    </Box>
  )
}